import { requireFun } from '@/utils'
export const formFile1 = [
  {
    prop: 'dictType',
    placeholder: '搜索名称',
    colNum: 6
  }
]
export const formFile2 = [
  {
    prop: 'itemName',
    placeholder: '搜索字典标签',
    colNum: 6
  }
]
export const tableCol1 = [
  {
    prop: 'dictType',
    label: '名称'
  },
  {
    prop: 'dictName',
    label: '描述'
  },
  {
    prop: 'operation',
    label: '操作',
    slotName: 'operationSlot'
  }
]
export const tableCol2 = [
  {
    prop: 'dictName',
    label: '所属字典'
  },
  {
    prop: 'itemName',
    label: '字典标签'
  },
  {
    prop: 'itemValue',
    label: '字典值'
  },
  {
    prop: 'sortNumber',
    label: '排序'
  },
  {
    prop: 'operation',
    label: '操作',
    slotName: 'operationSlot'
  }
]

export const tableCol3 = [
  {
    prop: 'dictName',
    label: '所属字典'
  },
  {
    prop: 'itemName',
    label: '字典标签'
  },
  {
    prop: 'itemValue',
    label: '字典值'
  },
  {
    label: '是否启用',
    slotName: 'statusSlot'
  },
  {
    prop: 'sortNumber',
    label: '排序'
  },
  {
    prop: 'operation',
    label: '操作',
    slotName: 'operSlot'
  }
]

export const addAndEditForm1 = [
  {
    prop: 'dictType',
    label: '名称',
    rule: [
      {
        required: true,
        message: requireFun('名称')
      }
    ]
  },
  {
    prop: 'dictName',
    label: '描述'
  }
]
export const addAndEditForm2 = [
  {
    prop: 'itemName',
    label: '字典标签',
    rule: [
      {
        required: true,
        message: requireFun('名称')
      }
    ]
  },
  {
    prop: 'itemValue',
    label: '字典值',
    rule: [
      {
        required: true,
        message: requireFun('字典值')
      }
    ],
    slotName: 'itemValueSlot'
  },
  {
    prop: 'sortNumber',
    label: '排序',
    slotName: 'sortSlot',
    rule: [
      {
        required: true,
        message: requireFun('排序')
      }
    ]
  }
]
